<template>
  <div class="m-index">
    <TopBar :type="1" :logo="logo" />
    <div
      class="service"
      v-if="bottomInfo.waiter_qrcode"
      @click="showCode = true"
    >
      联系<br />客服
    </div>
    <swiper
      class="m-banner"
      ref="bannerSwiper"
      :options="swiperOptionsBanner"
      v-if="bannerList.length"
    >
      <swiper-slide
        v-for="(item, index) in bannerList"
        :key="item.image + '_' + index"
      > 
        <img v-if="!item.link" class="banner-img" :src="item.image" />
        <a :href="item.link" v-else>
          <img class="banner-img" :src="item.image" />
        </a>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="menu" v-if="menuList && menuList.length > 0">
      <div
        v-for="menu in menuList"
        :key="menu.name"
        class="menu-link"
        @click="menuJump(menu.url)"
      >
        <img :src="menu.icon" class="menu-img" />
        <p class="menu-title">{{ menu.name }}</p>
      </div>
    </div>
    <!-- 直播 -->
    <div class="modal-content live" v-if="liveModule[0]">
      <div class="live-title">
        {{ liveModule[0].liveNow ? "正在直播" : "即将直播"
        }}<span class="time">{{ liveModule[0].live_date }}</span>
      </div>
      <div
        class="live-content"
        @click="$router.push('/live/' + liveModule[0].channel_no +'/'+ liveModule[0].id)"
      >
        <img class="live-img" :src="liveModule[0].mobile_image" />
        <div class="live-info">
          <p class="live-info-title vertical-ellipsis">
            {{ liveModule[0].name }}
          </p>
          <p class="live-info-lecturer">
            主讲人：{{ liveModule[0].teacher_name }}
          </p>
          <div class="live-btn" v-if="liveModule[0].liveNow">进入直播</div>
        </div>
      </div>
      <swiper
        class="live-list"
        ref="liveSwiper"
        :options="swiperOptionsLive"
        v-if="liveModule.length > 1"
      >
        <!-- v-if="item.id != liveModule[0].id" -->
        <swiper-slide v-for="item in liveModule" :key="item.id">
          <router-link class="list-info" :to="'/live/' + item.channel_no">
            <p class="list-info-title">
              {{ item.live_date
              }}<span class="list-info-lecturer"
                >主讲人：{{ item.teacher_name }}</span
              >
            </p>
            <p class="list-info-name">{{ item.name }}</p>
          </router-link>
        </swiper-slide>
      </swiper>
    </div>
    <!-- 模块 -->
    <div
      class="modal-content"
      v-for="(item, index) in modulelist"
      :key="item.id"
      v-if="item.list && item.list.length > 0"
      :class="
        item.style_id == 4
          ? 'lecturer'
          : item.style_id == 5
          ? 'xilie'
          : 'style_' + item.style_id
      "
    >
      <div class="modal-title">
        <span>{{ item.name }}</span>
        <router-link class="more" :to="'/lecturer'" v-if="item.style_id == 4"
          >更多</router-link
        >
        <router-link
          class="more"
          :to="'/seriesLessons'"
          v-if="item.style_id == 5"
          >更多</router-link
        >
        <router-link
          class="more"
          :to="'/courseShowList?id=' + item.id"
          v-if="
            item.style_id != 5 &&
            item.style_id != 4 &&
            item.style_id != 7 &&
            item.style_id != 8
          "
          >更多</router-link
        >
        <router-link
          class="more"
          :to="'/activeList/' + item.id + '/' + item.name"
          v-if="item.style_id == 7 || item.style_id == 8"
          >更多</router-link
        >
      </div>
      <!-- 左右滚动 -->
      <ul
        class="modal-list"
        v-if="
          item.list &&
          item.list.length > 0 &&
          item.style_id !== 4 &&
          item.style_id !== 5 &&
          item.style_id !== 8
        "
      >
        <li
          class="modal-list-info"
          :class="{ active: item.style_id == 7 }"
          v-for="subItem in item.list"
          :key="subItem.id"
        >
          <div v-if="item.style_id == 7" @click="toActiveInfo(subItem)">
            <img
              v-lazy="subItem.image"
              class="list-info-img"
              :key="subItem.image"
            />
            <div class="list-info-content">
              <p class="info-content-title vertical-ellipsis">
                {{ subItem.name }}
              </p>
              <p class="info-sub-title vertical-ellipsis">{{ subItem.desc }}</p>
            </div>
          </div>
          <router-link :to="'/recordedInfo/' + subItem.data_id" v-else>
            <img
              v-lazy="subItem.image"
              class="list-info-img"
              :key="subItem.image"
            />
            <div class="list-info-content">
              <p class="info-content-title vertical-ellipsis">
                {{ subItem.name }}
              </p>
              <p class="info-content-subtitle">{{ subItem.duration }}课时</p>
              <div
                class="info-content-price"
                v-if="websiteData.is_course_pay == 1"
              >
                <span class="price">
                  <span v-if="subItem.price != '0.00'">￥</span
                  >{{ subItem.price == "0.00" ? "免费" : subItem.price }}</span
                >
              </div>
            </div>
          </router-link>
        </li>
      </ul>
      <!-- 轮播 -->
      <swiper
        class="modal-list"
        ref="lecturerSwiper"
        :options="lecturerOptionsLive"
        v-if="item.list && item.list.length > 0 && item.style_id == 4"
      >
        <swiper-slide v-for="subItem in item.list" :key="subItem.id">
          <div class="lecturer-card">
            <img
              :src="subItem.avatar_34"
              class="lecturer-img"
              :key="subItem.image"
            />
            <div class="lecturer-content">
              <div class="lecturer-title">
                <p class="lecturer-name">{{ subItem.name }}</p>
                <!-- <div class="marks" v-if="subItem.level">
                  <div class="mark">{{ subItem.level }}</div>
                </div> -->
              </div>
              <p class="lecturer-intro">{{ subItem.introduce }}</p>
              <router-link
                :to="'/lecturerHomepage/' + subItem.data_id"
                class="lecturer-btn"
                >进入主页</router-link
              >
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <!-- 系列课 -->
      <ul
        class="xilie-list"
        v-if="item.list && item.list.length > 0 && item.style_id == 5"
      >
        <li
          class="xilie-list-info"
          v-for="subItem in item.list"
          :key="subItem.id"
        >
          <router-link
            :to="'/seriesLessonsInfo/' + subItem.data_id"
            class="clearfix"
          >
            <div class="xilie-img">
              <img v-lazy="subItem.image" :key="subItem.image" />
            </div>
            <div class="xilie-con">
              <p class="xilie-name">{{ subItem.name }}</p>
              <!-- <p class="xilie-teachers">
                <span v-for="(teacher, index) in subItem.teachers">{{teacher.name}}<span v-if="index<subItem.teachers.length-1">/</span></span>
              </p> -->
              <p class="xilie-intro">{{ subItem.intro }}</p>
              <p class="xilie-price" v-if="websiteData.is_course_pay == 1">
                <span class="unit">¥</span>{{ subItem.price }}
              </p>
            </div>
          </router-link>
        </li>
      </ul>
      <!-- 活动文章小块 -->
      <ul
        class="active-list"
        v-if="item.list && item.list.length > 0 && item.style_id == 8"
      >
        <li
          class="active-info"
          v-for="subItem in item.list"
          :key="subItem.id"
          @click="toActiveInfo(subItem)"
        >
          <img class="active-img" v-lazy="subItem.image" :key="subItem.image" />
          <div class="active-con">
            <p class="active-name vertical-ellipsis">{{ subItem.name }}</p>
            <p class="active-sub-title vertical-ellipsis">{{ subItem.desc }}</p>
          </div>
        </li>
      </ul>

      <div class="vert_box" v-if="advertList[index]">
        <div class="advert_image">
          <a :href="advertList[index].m_link">
            <img :src="advertList[index].m_image" />
          </a>
        </div>
      </div>
    </div>

    <!-- 文本课程 -->
    <div
      class="content-box clearfix textCourse"
      v-if="textCourseList.length > 0"
    >
      <div class="content lecturer-content modal-content">
        <div class="modal-title">
          <span>文本课程</span>
          <router-link :to="'/textCourse/list'" class="more">更多</router-link>
        </div>
        <ul class="lecturer-list" v-if="textCourseList.length > 0">
          <li
            class="lecturer-info"
            v-for="subItem in textCourseList"
            :key="subItem.id"
            @click="$router.push('/textCourse/detail?id=' + subItem.id)"
          >
            <img :src="subItem.image" class="lecturer-img" />
            <div class="cont">
              <div class="lecturer-name">
                {{ subItem.title }}
              </div>
              <div class="lecturer-course">
                <span>浏览量：</span>{{ subItem.view_number }}
              </div>
            </div>
          </li>
        </ul>
        <div class="modal-title" v-if="openClassList.length > 0">
          <span>推荐班级</span>
          <router-link :to="'/openClass'" class="more">更多</router-link>
        </div>
        <ul class="lecturer-list openClass" v-if="openClassList.length > 0">
          <li
            class="lecturer-info"
            v-for="subItem in openClassList"
            :key="subItem.id"
          >
            <img :src="subItem.image" class="lecturer-img" />
            <div class="cont">
              <div class="lecturer-name">
                {{ subItem.name }}
              </div>
              <div class="lecturer-course">
                <span></span>{{ subItem.content }}
              </div>
              <p class="btn" @click="sightBtn(subItem.id)">报名</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- 公开考试 -->
    <div class="modal-content comm-exam-cont" v-if="commonExamList.length > 0">
      <div class="modal-title">
        <span>公开考试</span>
        <router-link to="/commonExam/list" class="more">更多</router-link>
      </div>
      <ul class="xilie-list">
        <li v-for="examItem in commonExamList" :key="examItem.id">
          <p class="exam_name">{{ examItem.exam_name }}</p>
          <div class="time_">
            <p>开始时间：{{ examItem.start_date }}</p>
            <p>结束时间：{{ examItem.end_date }}</p>
          </div>
          <div class="down_num">限制考试次数：{{ examItem.limit_desc }}</div>
          <div
            class="btn"
            @click="$router.push('/commonExam/text/' + examItem.id)"
          >
            去考试
          </div>
        </li>
      </ul>
    </div>

    <div class="loading">
      <div class="loadEffect" v-if="loadingState">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <p class="bottom" v-if="bottomInfo.id != 67">
      {{ bottomInfo.fae ? bottomInfo.fae : "中工科创" }} · 技术支持
    </p>
    <p class="phone_text" v-if="bottomInfo.id == 73">400-0123-823</p>
    <div
      v-if="bottomInfo.id == 67 && showCode"
      class="code-box"
      @click="showCode = false"
    >
      <div
        class="code codeBgm"
        :style="'background-image:url(' + bottomInfo.waiter_qrcode + ')'"
      >
        截图保存二维码，添加客服微信
      </div>
    </div>
    <div v-else-if="showCode" class="code-box" @click="showCode = false">
      <div
        class="code"
        :style="'background-image:url(' + bottomInfo.waiter_qrcode + ')'"
      >
        截图保存二维码，添加客服微信
      </div>
    </div>
    <!-- {{$store.state.websiteState}} -->

    <!-- 劳动关系学院-分站 领取证书功能 -->
    <div class="ldgx_mask_cont_box" v-if="cerMaskState">
      <div class="cont_data_info" v-if="cerShowIndex == 1">
        <p class="close" @click="closeCerMask()"><img src="@/assets/m/pop/close_icon.png" alt="" /></p>
        <p class="text_title">领取培训证书</p>
        <div class="learn_time">
          <p class="text">当前学习总学时：</p>
          <p class="num">
              {{studyData.user_study_credit_hours}}<span>/{{studyData.total_credit_hours}}</span>
            </p>
        </div>
        <div class="tips_text" v-if="studyData.isGetCert==1">
          <p class="text1">您已领取过证书</p>
          
        </div>
        <div class="tips_text" v-else>
          <p class="text1" v-if="studyData.isCanGetCert==0">不满足领取证书条件<br/>满足总学时要求后方可领取</p>
          <p class="text2" v-if="studyData.isCanGetCert==1">满足领取证书条件</p>
        </div>
        <div class="get_btn disable" v-if="studyData.isGetCert==1 || studyData.isCanGetCert==0">领取证书</div>
        <div class="get_btn" v-else @click="getBookInfo()">领取证书</div>
      </div>
      <div class="msg_cont_info" v-if="cerShowIndex == 2">
        <p class="close" @click="closeCerMask()"><img src="@/assets/m/pop/close_icon.png" alt="" /></p>
        <div class="true_icon">
          <img src="@/assets/m/pop/true_icon.png" alt="" />
        </div>
        <div class="text_info">
          <p class="text">您已完成教学评价与入驻申请</p>
          <p class="text">证书将<span>发送至您的邮箱</span></p>
        </div>
        <div class="btn_sure" @click="closeCerMask()">确定</div>
      </div>
      <div class="question_cont_box" v-if="cerShowIndex == 3">
        <div class="head_title">
          <p class="text">评估问卷</p>
          <p class="close" @click="closeCerMask()">
            <img src="@/assets/m/pop/close_icon.png" alt="" />
          </p>
        </div>
        <div class="quest_cont_info">
          <div class="intro_text_info">
            <p>项目评估问卷 只可填写一次，感谢您的支持!</p>
          </div>
          <ul>
            <div class="tips_text">您对本次项目的总体评价</div>
            <div class="li_item">
              <li>
                <p class="title_text"><span>*</span>项目设计目标明确，立足当前，讲求实用，坚持需求导向，具有先进性，可持续性。</p>
                <p class="input"><input type="text" @blur="numInput(0)" v-model.number="questForm[0].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <li>
                <p class="title_text"><span>*</span>教学目标明确，课程设计科学，师资配置合理，能够因材施教。</p>
                <p class="input"><input type="text" @blur="numInput(1)" v-model.number="questForm[1].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <!-- <li class="w_100">
                <p class="title_text"><span>*</span>班级管理有序，纪律严明，教学环境设施满足教学要求;及时开展课程评估，注重过程监督和反馈。</p>
                <p class="input"><input type="text" @blur="numInput(2)" v-model.number="questForm[2].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <li>
                <p class="title_text"><span>*</span>工作人员履职尽责，严格遵守工作纪律和师德师风规范，服务意识强。</p>
                <p class="input"><input type="text" @blur="numInput(3)" v-model.number="questForm[3].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <li>
                <p class="title_text"><span>*</span>对培训项目整体开展情况的总评价。</p>
                <p class="input"><input type="text" @blur="numInput(4)" v-model.number="questForm[4].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li> -->
            </div>
            <div class="tips_text">您对听课的总体评价</div>
            <div class="li_item">
              <li>
                <p class="title_text"><span>*</span>政治观点正确，态度端正、仪表得体、关爱学生，体现立德树人、为党育人，严格遵守师德师风规范。</p>
                <p class="input"><input type="text" @blur="numInput(5)" v-model.number="questForm[5].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <li>
                <p class="title_text"><span>*</span>目标明确具体，体现知识传授，能力与素质培育，注重实践应用，针对性强，教材选用规范。</p>
                <p class="input"><input type="text" @blur="numInput(6)" v-model.number="questForm[6].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <li>
                <p class="title_text"><span>*</span>内容充实精准，深度广度适中，重点难点突出基础性、应用性和先进性统一，体现课程思政设计。</p>
                <p class="input"><input type="text" @blur="numInput(7)" v-model.number="questForm[7].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <li>
                <p class="title_text"><span>*</span>教师精神饱满，课堂气氛活跃，达成教学目标、重点难点问题有效解决。</p>
                <p class="input"><input type="text" @blur="numInput(8)" v-model.number="questForm[8].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <li>
                <p class="title_text"><span>*</span>教学方法选用适合学生特点，教学手段多样化，注重问题牵引，有效开展互动。</p>
                <p class="input"><input type="text" @blur="numInput(9)" v-model.number="questForm[9].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
            </div>
            <div class="tips_text">以下对“劳模和工匠人才创新工作室建设和实践”课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(10)" v-model.number="questForm[10].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <div class="tips_text">以下对“新时代大力弘扬三种精神的重要意义与实践路径“课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(11)" v-model.number="questForm[11].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <div class="tips_text">以下对“劳模工匠人才个人成长与创新团队建设”课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(12)" v-model.number="questForm[12].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <div class="tips_text">以下对“劳模工匠胜任能力与成长规律”课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(13)" v-model.number="questForm[13].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <div class="tips_text">以下对“创新思维与创新方法“课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(16)" v-model.number="questForm[16].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <div class="tips_text">以下对“创新工作室场景与实践”课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(17)" v-model.number="questForm[17].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <div class="tips_text">以下对“领衔人分享沙龙”课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(18)" v-model.number="questForm[18].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <li>
              <p class="title_text"><span>*</span>接收证书邮箱</p>
              <p class="input">
                <input  v-model="questForm[15].answer"  placeholder="请输入接收证书邮箱" >
              </p>
            </li>
            <li>
              <p class="title_text"><span>*</span>真实姓名</p>
              <p class="input">
                <input  v-model="questForm[23].answer"  placeholder="真实姓名（证书上使用，请务必真实）" >
              </p>
            </li>
            <li>
              <p class="title_text"><span></span>是否有下一步研修意向</p>
              <p class="input">
               <el-select v-model="questForm[19].answer" class="custom-placeholder">
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
               </el-select>
              </p>
            </li>
            <template v-if="questForm[19].answer=='是'">
              <li>
                <p class="title_text"><span>*</span>研修形式</p>
                <p class="input">
                <el-select v-model="questForm[20].answer" class="custom-placeholder">
                  <el-option label="线上" value="线上"></el-option>
                  <el-option label="线下" value="线下"></el-option>
                  <el-option label="线上线下相结合" value="线上线下相结合"></el-option>
                </el-select>
                </p>
              </li>
              <li>
                <p class="title_text"><span>*</span>研修内容</p>
                <p class="input">
                <el-select v-model="selectInfo" class="custom-placeholder">
                  <el-option label="知识技能类" value="知识技能类"></el-option>
                  <el-option label="时政前沿类" value="时政前沿类"></el-option>
                  <el-option label="创新创效类" value="创新创效类"></el-option>
                  <el-option label="其他" value="其他"></el-option>
                </el-select>
                </p>
                <p class="input" v-if="selectInfo=='其他'">
                  <input  v-model="questForm[21].answer"  placeholder="请输入研修内容" >
                </p>
              </li>
            </template>
            <li>
              <p class="title_text"><span>*</span>研修意向</p>
              <p class="textarea">
                <textarea name=""  v-model="questForm[22].answer" id="" placeholder="请输入"></textarea>
              </p>
            </li>
            <li>
              <p class="title_text">如果您对此培训的组织管理，课程设置，教学内容，教师教学，工作服务等方面有任何宝贵意见和建议，请告诉我们。</p>
              <p class="textarea">
                <textarea name=""  v-model="questForm[14].answer" id="" placeholder="请输入"></textarea>
              </p>
            </li>
          </ul>
          <div class="submit_btn" @click="questSubmit()">提交</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import TopBar from "@/m/common/topBar.vue";
export default {
  name: "Index",
  components: {
    TopBar,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      cerMaskState: false, //领取证书弹框sate
      cerShowIndex: 1,
      questForm: [
        { id: 1, answer: "" },
        { id: 2, answer: "" },
        { id: 3, answer: 1 },//已去掉
        { id: 4, answer: 1 },//已去掉
        { id: 5, answer: 1 },//已去掉
        { id: 6, answer: "" },
        { id: 7, answer: "" },
        { id: 8, answer: "" },
        { id: 9, answer: "" },
        { id: 10, answer: "" },
        { id: 11, answer: "" },
        { id: 12, answer: "" },
        { id: 13, answer: "" },
        { id: 14, answer: "" },
        { id: 15, answer:''}, //建议
        { id: 16, answer:''}, //邮箱
        { id: 17, answer:''}, //
        { id: 18, answer:''}, //
        { id: 19, answer:''}, //
        { id: 20, answer:'是'}, //研修形式
        { id: 21, answer:''}, //研修内容
        { id: 22, answer:''}, //研修意向
        { id: 23, answer:''}, //研究意向
        { id: 24, answer:''}, //姓名
      ],
      selectInfo:'',
      studyData:{},

      logo: "",
      showCode: false, //是否显示客服二维码
      menuList: "",
      bannerList: [], //banner
      liveModule: [], //直播模块
      modulelist: [], //模块所有数据
      //banner
      swiperOptionsBanner: {
        slidesPerView: 1, //显示slide的数量
        centeredSlides: true, //居中显示
        spaceBetween: 14, //间距
        loop: true, //循环
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        //setWrapperSize :true,  // Swiper使用flexbox布局(display: flex)，
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //是否可点击
        },
      },
      //直播滚动
      swiperOptionsLive: {
        direction: "vertical", //竖向滚动
        loop: false, //循环
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        loop: true,
        //setWrapperSize :true,  // Swiper使用flexbox布局(display: flex),
      },
      //讲师
      lecturerOptionsLive: {
        slidesPerView: 1.1, //显示slide的数量
        spaceBetween: 24, //间距
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        loop: true,
      },
      loadingState: true,
      websiteData: "",
      bottomInfo: "",
      setting: {
        keywords: "",
        description: "",
      },
      textCourseList: "", //文本课程
      openClassList: [],
      advertList: [], //广告位
      commonExamList: [], //公开考试列表
     
    };
  },
  metaInfo() {
    return {
      meta: [
        { name: "keywords", content: this.setting.keywords },
        { name: "description", content: this.setting.description },
      ],
    };
  },
  mounted() {
    this.getWebsiteInfo(); //获取网站信息
    this.getTextCourse(); //文本课程
    this.getNeedLogin(); //判断是否需要登录
    this.getBenner(); //banner
    this.getLliveModule(); //获取直播课堂
    this.getModule(); //获取首页模块
    this.getAdvert(); //获取首页模块
    this.getOpenExam(); //获取公开考试列表
    this.websiteData = this.$store.state.websiteState;

    //微信登录
    if (this.$route.query.noncestr) {
      this.weLoin(this.$route.query.noncestr);
    }
  },
  methods: {
    ...mapMutations(["ShowMLoginModal", "ShowTipModal", "CloseTipModal"]), //登录弹窗
    //获取网站信息
    getWebsiteInfo() {
      this.$axios.post(`/v1/websiteInfo`, { is_pc: 2 }).then((res) => {
        this.bottomInfo = res.data.site_info;
        localStorage.setItem("siteInfo", JSON.stringify(res.data.site_info));
        localStorage.setItem("navigation", JSON.stringify(res.data.navigation));
        this.logo = res.data.site_info.site_logo;
        this.menuList = res.data.navigation; //获取金刚区菜单
        document.getElementById("titleName").innerText =
          res.data.site_info.name;
        this.getMeat();
      });
    },
    // 获取广告条
    getAdvert() {
      this.$axios
        .post(`/v1/home/advert`, {
          type: "1",
        })
        .then((res) => {
          if (res.code == 0) {
            this.advertList = res.data;
          }
        });
    },
    getMeat() {
      this.setting.keywords = this.bottomInfo.keyword;
      this.setting.description = this.bottomInfo.desc;
    },
    //判断是否需要登录
    getNeedLogin() {
      if (localStorage.getItem("needLogin")) {
        this.ShowMLoginModal();
        localStorage.removeItem("needLogin");
      }
    },
    //菜单跳转
    menuJump(url) {
      if (url.includes("http")) {
        window.location.href = url;
      } else {
        if(url=='/getCert'){
          this.routerBtn()
        }else{
          this.$router.push(url);
        }
        
      }
    },
    //获取banner
    getBenner() {
      this.$axios
        .post(`/v1/home/banner`, {
          type: "2",
        })
        .then((res) => {
          if (res.data.length == 1) {
            this.swiperOptionsBanner.slidesPerView = 1;
            this.swiperOptionsBanner.loop = false;
          }
          this.bannerList = res.data;
        });
    },
    //获取直播课堂
    getLliveModule() {
      this.$axios.post(`/v1/home/liveModule`).then((res) => {
        let nowDta = Date.now() / 1000;

        for (let i = 0; i < res.data.length; i++) {
          if (nowDta > res.data[i].live_time && nowDta < res.data[i].end_time) {
            res.data[i].liveNow = true;
          } else {
            res.data[i].liveNow = false;
          }
        }

        this.liveModule = res.data;
        this.liveSelect = res.data[0];
      });
    },
    //获取首页模块
    getModule() {
      let list = [];
      this.$axios.post(`/v1/home/module`).then((res) => {
        if (res.data.length > 0) {
          this.getModuleData(res.data);
        } else {
          this.loadingState = false;
        }
      });
    },
    //获取模块数据
    async getModuleData(moduleData) {
      this.modulelist = moduleData;

      for (let i = 0; i < moduleData.length; i++) {
        let res = await this.$axios.post(`/v1/home/moduleData`, {
          id: moduleData[i].id,
        });

        for (let i = 0; i < this.modulelist.length; i++) {
          if (res.data.total) {
            for (let o = 0; o < res.data.data.length; o++) {
              if (res.data.data[o].module_id == this.modulelist[i].id) {
                if (this.modulelist[i].list) {
                  this.$set(this.modulelist[i].list, o, res.data.data[o]);
                } else {
                  this.$set(this.modulelist[i], "list", []);
                  this.$set(this.modulelist[i].list, o, res.data.data[o]);
                }
              }
            }
          } else {
            for (let o = 0; o < res.data.length; o++) {
              if (res.data[o].module_id == this.modulelist[i].id) {
                if (this.modulelist[i].list) {
                  this.$set(this.modulelist[i].list, o, res.data[o]);
                } else {
                  this.$set(this.modulelist[i], "list", []);
                  this.$set(this.modulelist[i].list, o, res.data[o]);
                }
              }
            }
          }
        }

        this.loadingState = false;
      }
    },

    // 文本课程
    getTextCourse() {
      this.$axios.post(`/v1/home/textCourse`).then((res) => {
        if (res.code == 0) {
          this.textCourseList = res.data;
        }
      });
      // 推荐班级
      this.$axios
        .post(`/v1/openClass/list`, { page: "1", per_page: "3" })
        .then((res) => {
          if (res.code == 0) {
            this.openClassList = res.data.data;
          }
        });
    },
    // 报名
    sightBtn(id) {
      let _this = this;
      _this.ShowTipModal({
        text: "确定报名该班级？", //提示弹窗内容
        showBtns: true,
        subBtnText: "报名",
        confirmFun: function () {
          _this.CloseTipModal();
          // _this.payNowBtn(_this.courseInfo.detail.id);
          this.$axios
            .post(
              `/v1/openClass/joinOpenClass`,
              { class_id: id },
              { useLog: true }
            )
            .then((res) => {
              _this.ShowTipModal({
                text: res.msg, //提示弹窗内容
              });
            });
        },
      });
    },

    async weLoin(code) {
      await this.$axios
        .post(`/v1/px/login/getUserinfo`, { noncestr: code })
        .then((res) => {
          if (res.code == 0) {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
              ico: "success",
            });
            localStorage.setItem("auth", JSON.stringify(res.data.auth));
            localStorage.setItem("user", JSON.stringify(res.data.user));
            localStorage.setItem("userInfo", JSON.stringify(res.data.user));
            localStorage.setItem("logoDate", Date.parse(new Date()) / 1000);
          } else {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
            });
          }
        });
    },
    //跳转活动文章详情
    toActiveInfo(data) {
      if (data.type === 0) {
        this.$router.push("/activeInfo/" + data.data_id);
      } else if (data.type === 1) {
        location.href = data.m_link;
      }
    },
    // 获取公开考试
    getOpenExam() {
      this.$axios
        .post(`/v1/commonExam/list`, { page: 1, per_page: 4 })
        .then((res) => {
          if (res.code == 0) {
            this.commonExamList = res.data.data;
          }
        });
    },


    // 分站-劳动关系学院begin
     // 劳动关系学院路由-获取证书点击
      routerBtn() {
        if(!localStorage.getItem("userInfo")){
          this.ShowMLoginModal()
        }else{
          this.$axios.post(`/v1/course/studyTotalDuration`,{},{useLog:true}).then(res=>{
            console.log(res)
            this.studyData = res.data
            this.cerMaskState = true
            this.cerShowIndex=1
          })
        }
        
      },

      // 领取证书
      getBookInfo(){
        this.cerShowIndex=3
      },

      // 提交获取证书的表单
      questSubmit(){
        console.log('ppp')
        for(let i=0;i<this.questForm.length;i++){
          if((i <14  &&  i!=2 &&i!=3&&i!=4) && this.questForm[i].answer==''){
            this.ShowTipModal({
              text:'请按要求输入对应分值后提交' , //提示弹窗内容
              ico: "none",
            });
            return
            // return this.$message.error('请按要求输入对应分值后提交')
          }
          if( (i==16 || i==17 || i==18 )&& this.questForm[i].answer==''){
            this.ShowTipModal({
                text:'请按要求输入对应分值后提交' , //提示弹窗内容
                ico: "none",
            });
            return
          }
        }
        var re = /^\w+@[a-z0-9]+\.[a-z]{2,4}$/;
        if(!re.test(this.questForm[15].answer)){
          this.ShowTipModal({
              text:'请输入正确的邮箱地址' , //提示弹窗内容
              ico: "none",
            });
            return
          // return this.$message.error('请输入正确的邮箱地址')
        }
        if(this.selectInfo!='其他'){
          this.questForm[21].answer = this.selectInfo
        }else{
          this.questForm[21].answer = this.selectInfo + this.questForm[21].answer
        }
        if(this.questForm[19].answer=='是'){
          if(this.questForm[20].answer==''){
            return this.$message.error('请输入研修形式')
          }
          if(this.questForm[21].answer==''){
            return this.$message.error('请输入研修内容')
          }
        }else{
          his.questForm[20].answer=''
          his.questForm[21].answer=''
        }
        if(this.questForm[22].answer==''){
          return this.$message.error('请输入研修意向')
        }
        if(this.questForm[23].answer==''){
          return this.$message.error('请输入真实姓名')
        }
        this.$axios.post(`/v1/course/submitCertForm`,this.questForm,{useLog:true}).then(res=>{
          console.log(res)
          if(res.code == 0){
            this.cerShowIndex=2
          }else{
            this.$message.error(res.msg)
          }
        })
      },

      // 关闭领取证书弹框
      closeCerMask(){
        this.cerMaskState = false
        this.cerShowIndex=1
        for(let i=0;i<this.questForm.length;i++){
          this.questForm[i].answer=''
        }
      },
      numInput(num){
        if(typeof this.questForm[num].answer === 'number'){
          if(Number(this.questForm[num].answer)<1 && Number(this.questForm[num].answer)!=''){
            this.questForm[num].answer= 1
          }
          if(num>9){
            if(Number(this.questForm[num].answer)>10){
              this.questForm[num].answer= 10
            }
          }else{
            if(Number(this.questForm[num].answer)>5){
              this.questForm[num].answer= 5
            }
         }
        }else{
          this.questForm[num].answer=this.questForm[num].answer.replace(/\D/g,'')
        }
      
      },

    // 分站-劳动关系学院end

  },
};
</script>

<style lang="scss" scoped>
.m-index {
  background-color: #f7f7f7;
}
.m-banner {
  width: 750px;
  height: 278px;
  padding-top: 28px;
  background-color: #fff;
  .swiper-slide {
    display: flex;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
    .banner-img {
      display: block;
      width: 100%;
      height: 278px;
      transition: width 0.3s, height 0.3s;
      -webkit-transition: width 0.3s, height 0.3s; /* Safari */
    }
  }
  .swiper-slide-prev,
  .swiper-slide-next {
    border-radius: 6px;
    overflow: hidden;
    .banner-img {
      display: block;
      width: 685px;
      height: 254px;
      border-radius: 6px;
      transition: width 0.3s, height 0.3s;
      -webkit-transition: width 0.3s, height 0.3s; /* Safari */
    }
  }
  ::v-deep .swiper-pagination {
    .swiper-pagination-bullet {
      width: 32px;
      height: 4px;
      background-color: #ccc;
      border-radius: 2px;
      opacity: 1;
      margin: 0 5px;
    }
    .swiper-pagination-bullet-active {
      background-color: #254ed4;
    }
  }
}
.menu {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  padding: 44px 50px 0;
  border-bottom: 1px solid #f5f5f5;
  background-color: #fff;
  .menu-link {
    display: block;
    width: 120px;
    margin: 0 50px 44px 0;
    .menu-img {
      display: block;
      width: auto;
      height: 80px;
      margin: 0 auto 17px;
    }
    .menu-title {
      font-size: 28px;
      color: #999999;
      text-align: center;
    }
  }
  .menu-link:nth-child(4n) {
    margin-right: 0;
  }
}
.modal-content {
  padding: 38px 28px;
  padding-right: 0;
  background-color: #fff;
  &.live {
    padding-right: 28px;
    padding-top: 44px;
    margin-bottom: 10px;
  }
  .modal-title {
    font-size: 36px;
    color: #333;
    font-weight: 600;
    margin-bottom: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .more {
      padding-right: 40px;
      font-size: 28px;
      color: #999;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 40px;
        height: 40px;
        background: url(~@/assets/arrows.png) no-repeat center center;
        background-size: 100%;
        transform: rotate(-90deg);
        position: absolute;
        top: 0;
        right: 0;
      }

      margin-right: 30px;
    }
  }
  .modal-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0 0 10px 10px;
    &::-webkit-scrollbar {
      //隐藏滚动条
      display: none;
    }
    .modal-list-info {
      flex-shrink: 0;
      width: 386px;
      height: 454px;
      background: #fff;
      box-shadow: 0px 0px 10px 0px rgba(0, 12, 40, 0.14);
      border-radius: 7px;
      overflow: hidden;
      margin-right: 28px;
      &.active {
        height: 360px;
      }
    }
    .list-info-img {
      width: 100%;
      height: 214px;
    }
    .list-info-content {
      padding: 10px 20px 0;
    }
    .info-content-title {
      height: 74px;
      font-size: 28px;
      color: #333;
      line-height: 37px;
    }
    .info-sub-title {
      font-size: 24px;
      color: #999;
      -webkit-line-clamp: 1;
    }
    .info-content-subtitle {
      font-size: 24px;
      color: #999;
    }
    .info-content-price {
      padding: 28px 4px 0;
      border-top: 1px solid #e5e5e5;
      margin-top: 27px;
      font-size: 30px;
      color: #cd1b14;
      font-weight: bold;
    }
  }
  &.lecturer {
    background-color: #f7f7f7;
    .lecturer-card {
      box-sizing: border-box;
      width: 100%;
      padding: 28px;
      background-color: #fff;
      overflow: hidden;
      position: relative;
    }
    .lecturer-img {
      width: 158px;
      height: 208px;
      border-radius: 8px;
      float: left;
      object-fit: cover;
    }
    .lecturer-content {
      width: 340px;
      float: left;
      margin-left: 20px;
    }
    .lecturer-title {
      display: flex;
      align-items: center;
      .marks {
        margin: 0 0 0 10px;
        .mark {
          padding: 8px;
          background: rgba(255, 195, 11, 0.14);
          font-size: 24px;
          color: #f5a623;
        }
      }
    }
    .lecturer-name {
      font-size: 28px;
      color: #333;
    }
    .lecturer-intro {
      padding-top: 20px;
      max-height: 70px;
      line-height: 35px;
      font-size: 26px;
      color: #999;
      //多行省略号
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .lecturer-btn {
      width: 128px;
      height: 42px;
      line-height: 42px;
      border: 1px solid #254ed4;
      border-radius: 6px;
      text-align: center;
      font-size: 24px;
      color: #254ed4;
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
  &.xilie {
    padding-left: 0;
    padding-right: 0;
    .modal-title {
      padding-left: 28px;
    }
  }
  .xilie-list-info {
    padding: 28px;
    border-bottom: 1px solid #f5f5f5;
    .xilie-img {
      width: 308px;
      height: 200px;
      float: left;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 294px;
        height: 200px;
        background: linear-gradient(
          180deg,
          rgba(195, 204, 255, 0.14) 0%,
          rgba(125, 134, 168, 0.17) 45%,
          rgba(72, 82, 103, 0.3) 100%
        );
        box-shadow: 0px 1px 0px 0px #f5f5f5;
        border-radius: 6px;
        border-image: linear-gradient(
            180deg,
            rgba(159, 159, 159, 0),
            rgba(72, 82, 103, 0.07)
          )
          1 1;
        position: absolute;
        left: 0;
        right: 0;
        margin: 6px auto;
        z-index: 1;
      }
      &::after {
        content: "";
        display: block;
        width: 274px;
        height: 200px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(72, 82, 103, 0.13) 100%
        );
        box-shadow: 0px 1px 0px 0px #f5f5f5;
        border-radius: 6px;
        border-image: linear-gradient(
            180deg,
            rgba(159, 159, 159, 0),
            rgba(72, 82, 103, 0.06)
          )
          1 1;
        position: absolute;
        left: 0;
        right: 0;
        margin: 12px auto;
        z-index: 0;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
      }
    }
    .xilie-con {
      width: 356px;
      float: left;
      margin-left: 20px;
      position: relative;
    }
    .xilie-name {
      height: 80px;
      line-height: 40px;
      font-size: 28px;
      color: #333;
      //多行省略号
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .xilie-teachers {
      padding-top: 10px;
      font-size: 24px;
      color: #999;
    }
    .xilie-intro {
      font-size: 24px;
      color: #999;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: normal;
      word-wrap: break-word;
      max-height: 60px;
    }
    .xilie-price {
      padding-top: 28px;
      font-size: 32px;
      font-weight: bold;
      color: #cd1b14;
      .unit {
        font-size: 24px;
      }
    }
  }
  &.style_8 {
    padding-right: 28px;
  }
  .active-list {
    .active-info {
      box-sizing: border-box;
      padding: 27px 32px;
      box-shadow: 0px 0px 14px 1px rgba(0, 12, 40, 0.14);
      display: flex;
      justify-content: space-between;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 20px;
    }
    .active-img {
      width: 289px;
      height: 163px;
      border-radius: 6px;
    }
    .active-con {
      width: 320px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .active-name {
      font-size: 32px;
      color: #333;
    }
    .active-sub-title {
      height: 36px;
      font-size: 14px;
      color: #999;
      line-height: 36px;
      -webkit-line-clamp: 1;
    }
  }
}
.live-title {
  height: 44px;
  padding-left: 50px;
  font-size: 32px;
  color: #f5a623;
  background: url(~@/assets/live-now-ico.png) no-repeat;
  background-size: 44px;
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  .time {
    padding: 2px 10px;
    background-color: #f5f5f5;
    font-size: 22px;
    color: #999;
    margin-left: 38px;
  }
}
.live-content {
  display: flex;
  // justify-content: space-between;
  .live-img {
    // width: 242px;
    // height: 180px;
    width: 164px;
    height: 200px;
    border-radius: 6.63px;
  }
  .live-info {
    width: 462px;
    height: 180px;
    position: relative;
    margin-left: 30px;
  }
  .live-info-title {
    font-size: 28px;
    color: #333;
    height: 76px;
    line-height: 38px;
  }
  .live-info-lecturer {
    font-size: 24px;
    color: #999;
    margin-top: 14px;
  }
  .live-btn {
    padding: 10px 14px 10px 44px;
    background: url(~@/assets/video-ico.png) no-repeat 10px center #f5a623;
    background-size: auto 28px;
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 24px;
    color: #fff;
    text-align: center;
  }
}
.live-list {
  height: 154px;
  background-color: #f5f5f5;
  margin-top: 45px;
  .list-info {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 33px 20px 28px;
  }
  .list-info-title {
    padding-left: 44px;
    background: url(~@/assets/clock-ico.png) no-repeat left center;
    background-size: auto 24px;
    font-size: 22px;
    color: #999;
  }
  .list-info-lecturer {
    font-size: 24px;
    color: #999;
    margin-left: 28px;
  }
  .list-info-name {
    box-sizing: border-box;
    width: 490px;
    height: 45px;
    line-height: 45px;
    padding-left: 44px;
    margin-top: 24px;
    font-size: 28px;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 1px;
      height: 45px;
      background-color: #ccc;
      position: absolute;
      top: 0;
      left: 12px;
    }
  }
}

// 公开考试样式
.comm-exam-cont {
  ul {
    width: 96%;
  }
  li {
    background: #fff;
    box-shadow: 0px 0px 14px 1px rgba(0, 12, 40, 0.14);
    border-radius: 12px;
    box-sizing: border-box;
    padding: 30px;
    p.exam_name {
      font-size: 32px;
      margin-bottom: 10px;
    }
    div.time_ {
      p {
        font-size: 24px;
        color: #666;
        margin-bottom: 10px;
      }
    }
    .down_num {
      font-size: 24px;
      color: #666;
    }
    div.btn {
      display: inline-block;
      background: #254ed4;
      padding: 10px 20px;
      margin-top: 20px;
      cursor: pointer;
      border-radius: 10px;
      color: #fff;
      font-size: 28px;
    }
  }
}

.bottom {
  padding: 35px 0;
  font-size: 26px;
  color: #999;
  text-align: center;
  background-color: #fff;
}
.phone_text {
  background: #fff;
  text-align: center;
  font-size: 26px;
  color: #999;
  padding: 0 0 35px;
}
.loading {
  padding-top: 30px;
  background-color: #fff;
}
// 加载中...
.loadEffect {
  width: 100px;
  height: 100px;
  position: relative;
  margin: 0 auto;
}
.loadEffect span {
  display: inline-block;
  width: 30px;
  height: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: rgb(203, 207, 203);
  position: absolute;
  -webkit-animation: load 1.04s ease infinite;
}
@-webkit-keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.loadEffect span:nth-child(1) {
  left: 0;
  top: 50%;
  margin-top: -5px;
  -webkit-animation-delay: 0.13s;
}
.loadEffect span:nth-child(2) {
  left: 10px;
  top: 20px;
  -webkit-transform: rotate(45deg);
  -webkit-animation-delay: 0.26s;
}
.loadEffect span:nth-child(3) {
  left: 50%;
  top: 10px;
  margin-left: -15px;
  -webkit-transform: rotate(90deg);
  -webkit-animation-delay: 0.39s;
}
.loadEffect span:nth-child(4) {
  top: 20px;
  right: 10px;
  -webkit-transform: rotate(135deg);
  -webkit-animation-delay: 0.52s;
}
.loadEffect span:nth-child(5) {
  right: 0;
  top: 50%;
  margin-top: -5px;
  -webkit-transform: rotate(180deg);
  -webkit-animation-delay: 0.65s;
}
.loadEffect span:nth-child(6) {
  right: 10px;
  bottom: 20px;
  -webkit-transform: rotate(225deg);
  -webkit-animation-delay: 0.78s;
}
.loadEffect span:nth-child(7) {
  bottom: 10px;
  left: 50%;
  margin-left: -15px;
  -webkit-transform: rotate(270deg);
  -webkit-animation-delay: 0.91s;
}
.loadEffect span:nth-child(8) {
  bottom: 20px;
  left: 10px;
  -webkit-transform: rotate(315deg);
  -webkit-animation-delay: 1.04s;
}
.service {
  height: 80px;
  padding: 0 16px 0 64px;
  background: url(~@/assets/headset.png) no-repeat 20px center #fff;
  background-size: 32px;
  border-radius: 40px 0 0 40px;
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.2);
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 150px;
  z-index: 10;
}
.code-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  .code {
    width: 600px;
    padding: 58px 0 494px;
    background: url(~@/assets/code.jpg) no-repeat center 146px #fff;
    background-size: 388px;
    border-radius: 16px;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
  }
  .codeBgm {
    background: url(~@/assets/us_codeImg.jpg) no-repeat center 146px #fff;
    background-size: 388px;
  }
}
// 文本课程
.lecturer-content {
  .lecturer-list {
    li {
      display: flex;
      margin-bottom: 20px;
      img {
        width: 200px;
        height: 200px;
        border-radius: 10px;
      }
      .cont {
        margin-left: 15px;
        div.lecturer-name {
          font-size: 18px;
        }
        div.lecturer-course {
          margin-top: 100px;
          font-size: 14px;
          span {
            color: #999;
          }
        }
      }
    }
  }
}
// 推荐班级
.openClass {
  .lecturer-course {
    margin-top: 30px !important;
    margin-bottom: 20px;
  }
  p.btn {
    background: #9f1106;
    padding: 20px 40px;
    color: #fff;
    border-radius: 10px;
  }
}

// 广告位
.vert_box {
  padding: 76px 0 0;
  background: #fff;
  margin-left: -28px;
  img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
}

//证书领取弹框
.ldgx_mask_cont_box {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 991;
  .cont_data_info,
  .msg_cont_info,
  .question_cont_box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 620px;
    height: 620px;
    background: url("~@/assets/m/pop/bgm.png") no-repeat;
    background-size: 100% 100%;
  }
  .cont_data_info {
    .close {
      display: flex;
      justify-content: flex-end;
      img {
        width: 32px;
        height: 32px;
        margin: 30px 30px 0 0;
      }
    }
    p.text_title {
      text-align: center;
      margin-top: 8px;
      font-weight: 500;
      font-size: 44px;
      color: #333333;
    }
    .learn_time {
      width: 452px;
      height: 140px;
      background: linear-gradient(166deg, #dee8fe 0%, #f4f7fd 100%);
      border-radius: 16px 16px 16px 16px;
      margin: 38px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      p.text {
        font-weight: 400;
        font-size: 32px;
        color: #3d3d3d;
      }
      p.num {
        color: #1f52d3;
        font-size: 40px;
        font-weight: bold;
        span {
          font-size: 32px;
        }
      }
    }
    .tips_text {
      margin-top: 28px;
      p {
        font-size: 26px;
        text-align: center;
      }
      p.text1 {
        color: #ea0000;
      }
      p.text2 {
        color: #0bb828;
      }
    }
    .get_btn {
      margin: 106px auto 0;
      width: 280px;
      height: 88px;
      background: #1f52d3;
      border-radius: 88px;
      text-align: center;
      line-height: 88px;
      font-size: 28px;
      color: #ffffff;
    }
    .disable{
      background: rgba(31,82,211,0.1);
    }
  }
  .msg_cont_info {
    p.close {
      display: flex;
      justify-content: flex-end;
      img {
        width: 32px;
        height: 32px;
        margin: 30px 30px 0 0;
      }
    }
    .true_icon {
      width: 152px;
      height: 152px;
      margin: 40px auto 0;
      img {
        width: 100%;
      }
    }
    .text_info {
      margin-top: 25px;
      p.text {
        font-weight: 400;
        font-size: 30px;
        color: #3d3d3d;
        line-height: 56px;
        text-align: center;
        span {
          color: #1f52d3;
        }
      }
    }
    .btn_sure {
      margin: 74px auto 0;
      width: 280px;
      height: 88px;
      background: #1f52d3;
      border-radius: 206px 206px 206px 206px;
      text-align: center;
      line-height: 88px;
      font-size: 28px;
      color: #fff;
      cursor: pointer;
    }
  }
  .question_cont_box {
    width: 100%;
    height: 100vh;
    // background: #ffffff;
    background: url('~@/assets/m/pop/bgm1.png') no-repeat;
    background-size: 100% auto;
    box-sizing: border-box;
    .head_title{
         position: relative;
        p.close{
        position: absolute;
        right: 20px;
        top:20px;
        img{
          width: 32px;
          cursor: pointer;
        }
      }
      p.text{
        text-align: center;
        font-weight: 500;
        font-size: 32px;
        color: #2345CF;
        line-height: 32px;
        letter-spacing: 5px;
        line-height: 100px;
      }
      }
    .quest_cont_info {
      height: calc(100vh - 100px);
      overflow-y: auto;
      padding: 0 32px;
      box-sizing: border-box;
     
      
      .intro_text_info {
        margin-top: 10px;
        p {
          text-align: center;
          font-size: 28px;
        }
      }
      ul {
        .tips_text {
          font-weight: bold;
          margin-top: 40px;
          font-size: 28px;
        }
        div.li_item {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        li {
          margin-top: 20px;
          width: 100%;
          p.title_text {
            font-size: 26px;
            span {
              color: red;
            }
          }
          p.input {
            width: 100%;
            border: 1px solid #d9d9d9;
            height: 80px;
            border-radius: 8px;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            input {
              width: 96%;
              height: 96%;
              font-size: 28px;
            }
            .el-select {
              width: 100%;
              height: 100%;
            }
             ::v-deep .el-input__inner{
              border: none;
            }
            // input::placeholder {
            //   font-size: 28px;
            // }
            .custom-placeholder ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              font-size: 28px; /* 设置您想要的字体大小 */
              color: grey; /* 设置您想要的颜色 */
            }
            .custom-placeholder :-ms-input-placeholder { /* Internet Explorer 10-11 */
              font-size: 28px;
            }
            .custom-placeholder ::-ms-input-placeholder { /* Microsoft Edge */
              font-size: 28px;
            }
            .custom-placeholder ::placeholder {
              font-size: 28px;
            }
          }
          p.textarea {
            width: 100%;
            height: 120px;
            border: 1px solid #d9d9d9;
            border-radius: 8px;
            margin-top: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            textarea {
              width: 96%;
              height: 86%;
              font-size: 28px;
              resize: none;
            }
          }
          input::placeholder {
            font-size: 28px;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }

          input[type="number"] {
            -moz-appearance: textfield;
          }
        }
      }
      .submit_btn {
        margin: 66px auto ;
        width: 280px;
        height: 88px;
        background: #1f52d3;
        border-radius: 88px;
        text-align: center;
        line-height: 88px;
        font-size: 28px;
        color: #ffffff;

      }
    }
  }
}
</style>
